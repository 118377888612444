// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-container {
    background-color: #f9f9f9;
    max-width: 740px;
    margin: 40px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    color: #333;
    font-family: 'Source Sans Pro', serif;;
}

.post-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 8px;
    font-family: 'Merriweather', sans-serif;
}

.post-meta {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    font-family: 'Merriweather', sans-serif;
}

.author-name {
    font-weight: bold;
    color: #2c4f61;
}

.post-date {
    font-style: italic;
}

.post-content {
    font-size: 20px;
    line-height: 1.6;
}

.post-content p {
    margin-bottom: 20px;
}

.post-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
    border-radius: 8px;
}

hr {
    border: none; /* Remove default border */
    height: 2px; /* Set height of the line */
    background-color: #ccc; /* Set color of the line */
    margin: 20px 0; /* Add margin for spacing */
  }`, "",{"version":3,"sources":["webpack://./src/Post.css"],"names":[],"mappings":"AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,WAAW;IACX,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;IACd,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY,EAAE,0BAA0B;IACxC,WAAW,EAAE,2BAA2B;IACxC,sBAAsB,EAAE,0BAA0B;IAClD,cAAc,EAAE,2BAA2B;EAC7C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');\n\n.post-container {\n    background-color: #f9f9f9;\n    max-width: 740px;\n    margin: 40px auto;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n    color: #333;\n    font-family: 'Source Sans Pro', serif;;\n}\n\n.post-title {\n    font-size: 28px;\n    color: #333;\n    margin-bottom: 8px;\n    font-family: 'Merriweather', sans-serif;\n}\n\n.post-meta {\n    font-size: 16px;\n    color: #666;\n    margin-bottom: 20px;\n    font-family: 'Merriweather', sans-serif;\n}\n\n.author-name {\n    font-weight: bold;\n    color: #2c4f61;\n}\n\n.post-date {\n    font-style: italic;\n}\n\n.post-content {\n    font-size: 20px;\n    line-height: 1.6;\n}\n\n.post-content p {\n    margin-bottom: 20px;\n}\n\n.post-content img {\n    max-width: 100%;\n    height: auto;\n    display: block;\n    margin: 20px 0;\n    border-radius: 8px;\n}\n\nhr {\n    border: none; /* Remove default border */\n    height: 2px; /* Set height of the line */\n    background-color: #ccc; /* Set color of the line */\n    margin: 20px 0; /* Add margin for spacing */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
