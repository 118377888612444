// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-bottom: 2px solid #f9f9f9;
  }
  
  .navbar a {
    margin: 0 15px;
    color: #282c34;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .navbar a:hover {
    color: #1653cb;
  }`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,gCAAgC;EAClC;;EAEA;IACE,cAAc;IACd,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    background-color: #f9f9f9;\n    border-bottom: 2px solid #f9f9f9;\n  }\n  \n  .navbar a {\n    margin: 0 15px;\n    color: #282c34;\n    text-decoration: none;\n    font-size: 16px;\n    font-weight: bold;\n    transition: color 0.3s ease;\n  }\n  \n  .navbar a:hover {\n    color: #1653cb;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
